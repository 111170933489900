

























































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { vxm } from "@/store";
import { Component, Vue, Watch } from "vue-property-decorator";
import ContactCard from "@/views/widgets/ContactCard.vue";
import FormButton from "@/views/parts/FormButton.vue";
import { cilBriefcase, cilHouse } from "@coreui/icons";
import { CIcon } from "@coreui/vue";
import TrueIDCard from "@/views/widgets/TrueIDCard.vue";
import WaitModal from "@/views/widgets/modals/WaitModal.vue";

import CustomCard from "@/views/viewcomponents/CustomCard.vue";
import SmallCard from "@/views/viewcomponents/SmallCard.vue";
import ProfileCard from "@/views/viewcomponents/ProfileCard.vue";

import {
  AddressDTO,
  Configuration,
  ContactDTO,
  ContactRelationship,
  ContactType,
  FileApiFactory,
  OptionDTO,
  ProfileApiFactory,
  ProfileDTO,
  Status,
} from "@shared_vue/openapi/myvaultapi";

import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import Axios from "axios";
import TinyCard from "@/views/viewcomponents/TinyCard.vue";
import DynamicInputModal from "@shared_vue/components/modals/DynamicInputModal.vue";
import { SelectOption } from "@shared_vue/types/SelectOption";

@Component({
  components: {
    TinyCard,
    TrueIDCard,
    FormButton,
    ContactCard,
    CIcon,
    WaitModal,
    CustomCard,
    ProfileCard,
    SmallCard,
    Cropper,
    DynamicInputModal,
  },
})
export default class MyProfilePage extends Vue {
  private auth = vxm.auth;
  private ui = vxm.ui;
  private icon1 = cilHouse;
  private icon2 = cilBriefcase;
  private publicPath = process.env.VUE_APP_STATIC_DIR;
  private profileApi = ProfileApiFactory(
    <Configuration>{ basePath: process.env.VUE_APP_MYVAULT_API_URL },
    process.env.VUE_APP_MYVAULT_API_URL
  );
  private showWait: boolean = false;
  private dob: string = "09 Sept 1979";
  prof: ProfileDTO = <ProfileDTO>{};
  myname: string | null = null;
  private editMode: boolean = false;
  private editBasics: boolean = false;
  private addressClicked: boolean = false;
  private field1: string = "";
  private field2: string = "";
  private image: any = null;

  private profileStore = vxm.profile;
  private homestreet: string = "";
  private homecity: string = "";
  private homestate: string = "";
  private homecounty: string = "";
  private homestate_abbr: string = "";
  private homezip: string = "";
  private homecountry: string = "";
  private homelatitude: string = "";
  private homelongitude: string = "";
  private genderPopup: boolean = false;

  private addressTypes: OptionDTO[] = [];
  private provinceOptions: OptionDTO[] = [];
  private genderTypeOptions: any = [];
  private languageOptions: any = [];
  private provinces: any = [];
  private addressTypeOptions: any = [];

  private selfie: string|null = null;
  private showAddressModal: boolean = false;
  private showSelfieModal: boolean = false;
  private fileApi = FileApiFactory(<Configuration>{basePath:process.env.VUE_APP_MYVAULT_API_URL},process.env.VUE_APP_MYVAULT_API_URL)

  private addresspopup() {
    this.showAddressModal=true;
  }

  private mapAddressTypes(addressOptions: OptionDTO[]){
    this.addressTypes=addressOptions;
    this.addressTypeOptions=addressOptions.map(o=>({value:o.id,label:o.text}));
  }

  private mapProvinceTypes(provinceOptions: OptionDTO[]){
    this.provinceOptions=provinceOptions;
    this.provinces=provinceOptions.map(o=>({value:o.id,label:o.text}));
  }

  private mapGenderTypes(genderOptions: OptionDTO[]) {
    this.genderTypeOptions=genderOptions.map(o=>({value:o.id,label:o.text}));
  }

  private mapLanguageOptions(languageOptions: OptionDTO[]){
    this.languageOptions=languageOptions.map(o=>({value:o.id,label:o.id}));
  }

  private waitABit = false;
  private selectedOption: any=[0];
  private addressName: string = "";

  private clearAddressPopup() {
    this.showAddressModal=false;
    this.field1='';
    this.homestreet='';
    this.homestate='';
    this.homecountry='';
    this.homecity='';
    this.homezip='';
    this.homelatitude='';
    this.homelongitude='';
    this.homestate_abbr='';
    this.addressName="";
  }
  //this data will be fetched by web service and cached
  private reset() {
    this.image = null;
    //@ts-ignore
    this.$refs.file.value=null;
    this.showSelfieModal = false;
  }

  private async updateRelationshipOnServer(contact: ContactRelationship) {
    console.log("going to save..");
    let saveResult = await this.profileApi.profileAddContactRelation(contact)
    console.log(saveResult);
    if (saveResult.status == 200) {
      this.fetchContacts();
    }
  }

  private saveResult(whichPopup: string) {
    if (whichPopup === 'DependentNew' || whichPopup === 'EmergencySelect'){
      let newContact = <ContactDTO>{
        // @ts-ignore
        contactType : [this.$refs.dynamicModal.el.find(x => x.key === 'relationship').value, (whichPopup === 'DependentNew')?ContactType.Dependent:ContactType.Emergency1],
        // @ts-ignore
        firstNames: this.$refs.dynamicModal.el.find(x => x.key === 'name').value,
        // @ts-ignore
        lastName: this.$refs.dynamicModal.el.find(x => x.key === 'surname').value,
        // @ts-ignore
        dateOfBirth: this.$refs.dynamicModal.el.find(x => x.key === 'dob').value,
        // @ts-ignore
        phonePrimary: this.$refs.dynamicModal.el.find(x => x.key === 'phone').value,

      }
      // @ts-ignore
      let existingId = this.$refs.dynamicModal.el.find(x => x.key === 'id').value;
      if (existingId) {
        console.log('Existing update...');
        newContact.id=parseInt(existingId);
        //run update not new :-O //we should maybe handle this logic internally on the web service tho
        //add relationship
        let newRelationship = <ContactRelationship>{
          contactType:[(whichPopup === 'DependentNew')?ContactType.Dependent:ContactType.Emergency1],
          contactId:existingId,
          profileGuid:this.profileStore.myProfile.guid
        };
        this.updateRelationshipOnServer(newRelationship);
        //add new relation
      } else {
        console.log('Going to save contact: ' + newContact);
        this.addContactToServer(newContact);
      }
    }

    if (whichPopup.startsWith("settings_modal_")) {
      let id = whichPopup.substr(15);
      console.log("updating contact with id: " + id);
      let id2 = parseInt(id);
      let newContact = <ContactDTO>{
        // @ts-ignore
        contactType: [
          // @ts-ignore
          this.$refs.dynamicModal.el.find((x) => x.key === "relationship").value,
        ],
        // @ts-ignore
        firstNames: this.$refs.dynamicModal.el.find((x) => x.key === "name")
            .value,
        // @ts-ignore
        lastName: this.$refs.dynamicModal.el.find((x) => x.key === "surname")
            .value,
        // @ts-ignore
        dateOfBirth: this.$refs.dynamicModal.el.find((x) => x.key === "dob")
            .value,
      };
      newContact.id = id2;
      console.log("Going to save contact: " + newContact);
      this.updateContactOnServer(newContact);
    }
  }

  private async updateContactOnServer(contact: ContactDTO) {
    console.log("going to save..");
    let saveResult = await this.profileApi.profileUpdateContact(contact);
    console.log(saveResult);
    if (saveResult.status == 200) {
      this.fetchContacts();
    }
  }
  
  private async fetchContacts() {
    console.log("fetching contacts");
    let contactList = await this.profileApi.profileGetMyContacts();
    console.log("contacts:" + contactList);
    if (
        contactList.status == 200 &&
        contactList.data &&
        contactList.data.length > 0
    ) {
      this.profileStore.setContacts(contactList.data);
    }
  }
  
  private async addContactToServer(contact: ContactDTO) {
    console.log("going to save..");
    let saveResult = await this.profileApi.profileAddContact(contact);
    console.log(saveResult);
    if (saveResult.status == 200) {
      this.fetchContacts();
    }
  }
  
  uploadCropImage() {
    // @ts-ignore
    const { canvas } = this.$refs.cropper.getResult();
    console.log('let us do this, sir')
    const outerthis = this;
    if (canvas) {
      const form = new FormData();
      canvas.toBlob((blob: any) => {
        form.append('file', blob);
        Axios.post(process.env.VUE_APP_MYVAULT_API_URL + '/file/NewSelfie', form,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            .then(function (response) {
              console.log(response.status);
              if (response.status==200){
                outerthis.reset();
                // outerthis.showSuccess();
                outerthis.getSelfie();
              }
            })
            .catch(function (error) {
              console.log(error);
            });
      })
    }
  }

  private loadImage(event: any) {
    // Reference to the DOM input element
    console.log('load image')
    let input = event.target;
    // Ensure that you have a file before attempting to read it
    if (input.files && input.files[0]) {
      console.log('got file')
      // create a new FileReader to read this image and convert to base64 format
      let reader = new FileReader();
      // Define a callback function to run, when FileReader finishes its job
      reader.onload = (e) => {
        // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
        // Read image as base64 and set to imageData
        console.log(e)
        this.image = e.target?.result;
      };
      // Start the reader job - read file as a data url (base64 format)
      reader.readAsDataURL(input.files[0]);
    }
  }

  get CurrentProfile(){
    return this.prof;
  }
  
  get PrimaryEmergencyContact() : ContactDTO|undefined{
    return this.prof.contacts?.find(c=>c.contactType.includes(ContactType.Emergency1));
  }
  
  get PrimaryEmergencyContactNumber() : string {
    if (this.PrimaryEmergencyContact){
      return this.PrimaryEmergencyContact.phonePrimary??'';
    } else {
      return '';
    }
  }
  get PrimaryEmergencyContactName() : string {
    if (this.PrimaryEmergencyContact){
      return this.PrimaryEmergencyContact.firstNames + ' ' + this.PrimaryEmergencyContact.lastName;
    } else {
      return '';
    }
  }

  @Watch('auth.idToken')
  onIdToken(val: boolean, oldval: boolean) {
    console.log('onidtoken')
    if (this.waitABit){
      this.waitABit=false;
      this.fetchAll();
    }
  }

  private fetchAll(){
    this.fetchProfile();
    this.fetchOptions('AddressType', this.mapAddressTypes);
    this.fetchOptions('Province', this.mapProvinceTypes); //I'm sure there is a more generic way to do this
    this.fetchOptions('AddressType', this.mapAddressTypes);
    this.fetchOptions('GenderIdentity', this.mapGenderTypes);
    this.fetchOptions('Language', this.mapLanguageOptions);
    this.showWait=false;

  }
  private goEditMode(){
    this.editMode=!this.editMode;
    if (!this.editMode){
      this.updateProfile();
    }
  }

  private goEditBasics(){
    this.editBasics=!this.editBasics;
  }

  private changeGender(){
    this.genderPopup=true;
  }

  private get GenderSymbol(){
    if (this.prof && this.prof.genderIdentityId) {
      return (this.prof.genderIdentityId.charAt(0).toUpperCase().toString() === 'M') ? '♂ \r\n Male' : '♀ \r\n Female'
        "M"
        ? "<span style='font-size: 1.6rem;'>♂</span><br>Male"
        : "<span style='font-size: 1.6rem;'>♀</span><br>Female";
    } else {
      return "??";
    }
  }

  private get MainLanguageSymbol(){
    if (this.prof&&this.prof.mainLanguageId){
      return this.prof.mainLanguageId;
    } else {
      return "??";
    }
  }


  private static aStatus() : Status{
    let status = <Status>{};
    status.description='Active';
    status.key='A'
    return status;
  }

  private submitAddress(){
    const outerThis = this;
    console.log('submitting address')
    this.showAddressModal=false;
    let newAddress = <AddressDTO>{};
    /*
    Id?: number;
    AddressType?: string;
    Name?: string;
    UnitNumber?: string | null;
    ComplexName?: string | null;
    StreetNumber?: string;
    StreetName?: string;
    Suburb?: string;
    City?: string;
    Province?: string;
    Country?: string;
    PostalCode?: string;
    IsPrimary?: boolean;
    Confirmed?: boolean;
     */
    newAddress.city=this.homecity;
    newAddress.suburb=this.homecounty;
    newAddress.country=this.homecountry;
    newAddress.isPrimary=this.selectedOption==1;
    newAddress.name=this.addressName;
    newAddress.postalCode=this.homezip;
    newAddress.provinceId=this.homestate_abbr;
    newAddress.addressTypeId=this.selectedOption;
    if (this.selectedOption==="Primary"){
      newAddress.isPrimary=true;
    }
    newAddress.streetName=this.homestreet;
    newAddress.latitude=this.homelatitude.toString();
    newAddress.longitude=this.homelongitude.toString();
    console.log(newAddress);
    this.profileApi.profileAddAddress(newAddress).then(res=>{
      this.prof=res.data; //should get replaced here
    }).catch(err=>{

    }).finally(()=>{
      outerThis.clearAddressPopup();
    })
  }

  private async fetchOptions(which: string, resultProcessor: Function) {
    let response = await this.profileApi.profileGetOptions(which)
    console.log(response.data)
    resultProcessor(response.data);
  }

  private getSelfie(){
    //need the signed URL unless we make all selfies public but that seems like a POPI thing
    this.fileApi.fileGetFileLinkByKey("selfie.png").then(r=>{
      console.log(r)
      this.selfie = r.data ;
    }).catch(reason => {console.log(reason)
      this.selfie = this.publicPath+'/images/profile-dummy3-300x300.png'})

  }

  private async fetchProfile() {

    let result2 = await this.profileApi.profileGetProfile();
    console.log('round2 is' + result2.data); //it works!
    this.prof = result2.data;
    this.profileStore.setProfile(result2.data);
    this.getSelfie()
  }

  private async updateProfile() {
    //too much effort to make different ones I think?
    let sendProf = this.prof;
    sendProf.contacts=undefined;
    let result = await this.profileApi.profileUpdateOwnProfile(<ProfileDTO>sendProf)
    if (result.status==200) { //might be good to make a PUT to only amend the deltas also
      this.prof = result.data
      this.editBasics=false; //add warnings for errors or maybe just background cache/retry?
      //in fact we could persist profile to the store but it seems like not worth the effort
    }
  }

  private form:any= {};
  
  private get selfContact() {
    return this.prof.contacts?.find(c=>c.contactType.includes(ContactType.Self));
  }

  private get primaryAddress(){
    let selfContact = this.selfContact;
    if (this.prof&&selfContact&&selfContact.addresses){
      return selfContact.addresses.find(a=>a.addressTypeId==="Primary");
    }
    return null;
  }

  private get primaryAddressCompact(){
    let selfContact = this.selfContact;
    if (this.prof&&selfContact&&selfContact.addresses){
      let homeAddress =  selfContact.addresses.find(a=>a.addressTypeId==="Primary");
      if (homeAddress!==undefined) {
        return `${homeAddress.streetName}, ${homeAddress.suburb}, ${homeAddress.city}, ${homeAddress.postalCode}, ${homeAddress.country}`
      }
    }
    
  }

  private get workAddress(){
    let selfContact = this.selfContact;
    if (this.prof&&selfContact&&selfContact.addresses){
      return selfContact.addresses.find(a=>a.addressTypeId==="Work");
    }
    return null;
  }

  private get workAddressCompact(){
    let workAddress = this.workAddress;
    
    if (workAddress) {
      return `${workAddress.streetName}, ${workAddress.suburb}, ${workAddress.city}, ${workAddress.postalCode}, ${workAddress.country}`
    }
    
  }

  get contactList(): ContactDTO[] | undefined {
    return this.profileStore.contactList;
  }

  get dependents(): ContactDTO[] | undefined {
    return this.contactList?.filter((c) =>
        c.contactType.includes(ContactType.Dependent)
    );
  }

  private contactSelected(which: any){ //which is an event which is annoying
    console.log('chose: ' + which); //reverse search this now
    let id = which.target.value;
    console.log('selecting contact with id: ' + id);
    let actualContact = this.contactList!.find(c=>c.id==id);

    //fetch details and populate popup
    // let actualContact = this.contactList!.find(c=>c.id == parseInt(which.value));
    // @ts-ignore
    this.$refs.dynamicModal.el.find((x) => x.key === "id").value = actualContact.id;
    // @ts-ignore
    this.$refs.dynamicModal.el.find((x) => x.key === "surname").value = actualContact.lastName;
    // @ts-ignore
    this.$refs.dynamicModal.el.find((x) => x.key === "name").value = actualContact.firstNames;
    // @ts-ignore
    this.$refs.dynamicModal.el.find((x) => x.key === "dob").value = actualContact.dateOfBirth;
    // @ts-ignore
    this.$refs.dynamicModal.el.find((x) => x.key === "relationship").value = actualContact.contactType.filter(ct=>ct!=='MEDICAL_AID_DEPENDENT')[0];
    // @ts-ignore
    this.$refs.dynamicModal.el.find((x) => x.key === "phone").value = actualContact?.phonePrimary??'';

  }
  
  get existingContacts(): SelectOption[] | undefined {
    return this.contactList?.map((el) =>  ({ value:el.id!.toString(), label:el.firstNames!}));
  }
  private relationshipOptions: SelectOption[] = [
    { value: "SPOUSE", label: "Spouse" },
    { value: "CHILD", label: "Child" },
    { value: "FATHER", label: "Father" },
    { value: "MOTHER", label: "Mother" },
  ];

  private get getBio(){
    if (this.prof && this.prof.bio){
      return this.prof.bio;
    } else {
      return "Enter some basic information about yourself"
    }
  }

  private get getBased(){
    if (this.primaryAddress){
      return this.primaryAddress.city;
    } else {
      return "??"
    }
  }

  private newInput(eventdata:any){
    console.log('newinput')
    console.log(eventdata)
    this.field1=eventdata;
    this.addressClicked = true;
  }
  mounted() {
    this.showWait = true;
    const outerThis = this;
    //hey don't forget a nice plz wait modal
    //do we want reminders in the store? I'm not really sure
    //wait for valid user if this was refreshed (I do not save token in store)
    if (!vxm.auth.idToken){ //wait a bit in case it is coming
      this.waitABit=true;
    } else {
      this.fetchAll();
    }
  }
}
