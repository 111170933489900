


































import {vxm} from "@/store";
import {Component, Vue, Prop, PropSync} from 'vue-property-decorator';


@Component
export default class TrueIDCard extends Vue {
    @Prop(String) readonly imagesrc: string ='genericperson.png';
    @PropSync('name', { type: String }) nameSync!: string;
    private ui = vxm.ui;

    private publicPath = process.env.VUE_APP_STATIC_DIR;


}
